import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";

const ContactForm = () => {
  const form = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    setIsLoading(true);
    emailjs
      .sendForm(
        "service_ckr1159",
        "template_5t0uuee",
        form.current,
        "QWFgNLd1WqsAXlEet",
        {
          to_email: "wally@tgesystems.com",
        }
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsLoading(false);
          setIsMessageSent(true);
        },
        (error) => {
          console.log(error.text);
          setIsLoading(false);
        }
      );
  };

  return (
    <div
      id="contact-menu"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#e7e7e7",
      }}
    >
      <div
        id="contact-form-div"
        style={{
          borderRadius: 5,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h3 className="title1">Contact US</h3>

        <form
          ref={form}
          onSubmit={sendEmail}
          style={{
            display: "flex",
            flexDirection: "column",
            // width: "500px",

            borderRadius: "8px",
          }}
        >
          <label style={{ marginBottom: "10px" }}>Name</label>

          <input
            type="text"
            name="user_name"
            placeholder="Your name"
            style={{
              marginBottom: "10px",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ddd",
            }}
          />
          <label style={{ marginBottom: "10px" }}>Email</label>
          <input
            type="email"
            name="user_email"
            placeholder="Your email"
            style={{
              marginBottom: "10px",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ddd",
            }}
          />
          <label style={{ marginBottom: "10px" }}>Message</label>
          <textarea
            name="message"
            placeholder="Your message"
            style={{
              marginBottom: "10px",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ddd",
              height: "100px",
            }}
          />
          <input
            type="submit"
            value={isLoading ? "Sending Message" : "Send "}
            className="btn  btn-lg page-scroll"
            disabled={isLoading}
            style={{ backgroundColor: "#2d80f3", color: "#fff" }}
          />
          {isMessageSent ? (
            <span
              style={{ textAlign: "center", marginTop: 10, color: "green" }}
            >
              Your message has been sent successfully.
            </span>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
