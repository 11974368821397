import React from "react";
import partnersImage from "../images/partners1.png";
export const Contact = () => {
  return (
    <div
      style={{
        minHeight: 250,
        background: "#2a393e",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        flexWrap: "wrap",
        padding: 20,
        gap: 50,
      }}
    >
      <div>
        <h1 style={{ color: "white" }}>TGE Systems LTD</h1>
        <h6>Copyright © 2022-2024 TGE Systems LTD</h6>
      </div>

      <div>
        <h5 style={{ color: "white", marginBottom: 20 }}>
          <h6>Contact us</h6>
        </h5>
        <h6>Address</h6>
        <span style={{ color: "white", display: "block" }}>
          86-90 Paul Street
        </span>
        <span style={{ color: "white", display: "block" }}>London,</span>
        <span style={{ color: "white", display: "block" }}>EC2A 4NE</span>
        <span style={{ color: "white", display: "block" }}>
          United Kingdom,
        </span>
      </div>

      <div
        style={{
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h5 style={{ color: "white", marginBottom: 20 }}>Partner</h5>
        <img src={partnersImage} alt="PartnersImage" />
      </div>
    </div>
  );
};
